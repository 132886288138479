<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="card p-2 mb-1">
      <TitleButton :showBtn="false" title="Investigation Report"/>

      <DateQuerySetter :inEnoughSpace="false" btnTitle="Go" @onClickGo="investigationInvoiceList">

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">
            Product/Service Type
          </label>
          <v-select
            placeholder="Select Product/Service Type"
            v-model="productType"
            :options="productTypes"
            label="text"
            :reduce="text => text.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label"> Product Group </label>
          <AsyncSelect
            placeholder="Select Product/Service"
            :api-service="fetchProductList"
            v-model="ancestorId"
            label="text"
            :reduce="text => text.id"
            :additional-query="groupQueryObj"
            :format-label="option => option.text"
            ref="groupSelector"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label"> Product/Service </label>
          <AsyncSelect
            placeholder="Select Product/Service"
            :api-service="fetchProductList"
            v-model="productId"
            label="text"
            :reduce="text => text.id"
            :additional-query="productQueryObj"
            :format-label="option => option.text"
            ref="productSelector"
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="col-12">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>

    <div class="card mt-1 px-2">
      <ListTable :tableItems="investigations"/>
    </div>

    <div class="px-2">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
    <Loader v-if="reportLoading"/>
  </div>
</template>

<script setup>
import {onMounted, ref, defineEmits, computed, watch, inject} from 'vue';
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleReport from '@/services/modules/inventory';
import handlePurchase from '@/services/modules/purchase';
import handleHospitalReport from "@/services/modules/hospital/report";
import {useRoute, useRouter} from "vue-router";
import ListTable from "@/components/molecule/company/hospital/investigation-invoice/ListTable.vue";
import Pagination from '@/components/atom/Pagination'
import {useStore} from "vuex";
import Loader from "@/components/atom/LoaderComponent.vue";

const {fetchHome, reportLoading} = handleReport();
const {fetchInvestigationInvoiceReport} = handleHospitalReport();
const {fetchProductList} = handlePurchase();

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const $router = useRouter()
const $route = useRoute()
const $store = useStore()

const productId = ref(null);
const ancestorId = ref(null);
const productType = ref(null);
const productSelector = ref(null);
const groupSelector = ref(null);
const productTypes = ref([]);

const investigations = ref([]);
const offset = 20;

const companyId = computed(() => $route.params.companyId);
const start = computed(() => $route.query.start);
const end = computed(() => $route.query.end);
const page = computed(() => $route.query.page);
const token = computed(() => localStorage.getItem('token'));
const productQueryObj = computed(() => {
  const queryObj = {};

  if (!!productType.value && productType.value !== '') {
    queryObj.product_type = productType.value;
  }

  if (!!ancestorId.value) {
    queryObj.ancestor_id = ancestorId.value;
  }

  return queryObj;

})
const groupQueryObj = computed(() => {
  const queryObj = {
    group_level: 1
  };

  if (!!productType.value) {
    queryObj.product_type = productType.value;
  }

  return queryObj;

})

const exportTable = () => {
  let query = getQuery();
  let url = `${process.env.VUE_APP_BASE_URL}/export/reports/investigation-invoice-report${query}&_token=${token.value}&is_export=1`;
  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.click();
};

const getQuery = () => {
  let query = '?company_id=' + companyId.value
  query += '&sale_type=investigation_invoice'
  if (!!productId.value) query += '&product_id=' + productId.value
  if (!!productType.value) query += '&product_type=' + productType.value
  if (!!ancestorId.value) query += '&ancestor_id=' + ancestorId.value
  if (!start.value && !end.value) return query
  if (start.value) query += '&start_date=' + start.value
  if (end.value) query += '&end_date=' + end.value
  if (page.value) query += '&page=' + page.value
  query += '&offset=' + offset
  return query
};

const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, $route.query)
  routeQuery.page = page
  await $router.push({path: $route.path, query: routeQuery})

  await investigationInvoiceList();
}

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
};

const setPaginationData = (data) => {
  $store.commit('setPaginationData', data)
};

const resetPagination = () => {
  $store.commit('resetPagination')
};

watch(ancestorId, () => {
  productSelector.value.clear();
  productId.value = null;
})

watch(productType, () => {
  productSelector.value.clear();
  groupSelector.value.clear();
  productId.value = null;
  ancestorId.value = null;
})

async function investigationInvoiceList() {
  reportLoading.value = true;
  try {
    const res = await fetchInvestigationInvoiceReport(getQuery());
    if (!res.status) {
      investigations.value = [];
      return resetPagination()
    }
    const {data, ...paginationData} = res.data;
    investigations.value = data;
    await setPagination(paginationData)
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    reportLoading.value = false;
  }
}

onMounted(async () => {
  reportLoading.value = true;
  try {
    const [ homeRes ] = await Promise.all([
      fetchHome()
    ]);
    if (homeRes.data && homeRes.data.product_type) {
      for (let key in homeRes.data.product_type) {
        productTypes.value.push({
          id : key,
          text : homeRes.data.product_type[key]
        });
      }
    }
    location.value = 'all';
  } finally {
    reportLoading.value = false;
  }
  await investigationInvoiceList()
});
</script>


<style scoped>
.head td {
  text-align: right;
}
</style>
